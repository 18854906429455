body {
  background-color: #121212;
  color: #ffffff;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controls {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-label {
  margin-right: 20px;
}

select {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}

button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 15px 30px;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #555;
  transform: scale(1.05);
}

button:active {
  background-color: #666;
  transform: scale(0.95);
}

p {
  margin-top: 20px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

input[type="checkbox"]:checked {
  background-color: #2fff2f;
  transform: scale(1.1);
}

input[type="checkbox"]:checked:active {
  transform: scale(0.9);
}

label {
  display: flex;
  align-items: center;
}

label input[type="checkbox"] {
  margin-right: 10px;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.form-select, .form-input, .form-button {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.form-select:hover, .form-input:hover, .form-button:hover {
  background-color: #555;
  transform: scale(1.05);
}

.form-select:active, .form-input:active, .form-button:active {
  background-color: #666;
  transform: scale(0.95);
}

.notification {
  background-color: #444;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  animation: slideUp 0.8s cubic-bezier(0.25, 1.25, 0.5, 1.2);
}

.notification span {
  flex-grow: 1;
  text-align: center;
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.close-button:hover {
  transform: scale(1.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes shatter {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5) rotate(90deg);
    opacity: 0;
  }
}

.queue-container {
  margin-top: 20px;
  position: relative;
}

.queue-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.queue-box {
  background-color: #ffeb3b;
  color: #000;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.show-result {
  background-color: #444;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  animation: slideUp 0.8s cubic-bezier(0.25, 1.25, 0.5, 1.2);
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translate(-50%, 50px) scale(0.9);
  }
  60% {
    opacity: 1;
    transform: translate(-50%, -10px) scale(1.1);
  }
  80% {
    transform: translate(-50%, 5px) scale(0.95);
  }
  100% {
    transform: translate(-50%, 0) scale(1);
  }
}

.controls {
  display: flex;
  align-items: flex-start; /* Tata elemen dalam flex baris */
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.select-checkbox-wrapper {
  display: flex;
  flex-direction: column; /* Susun elemen secara vertikal */
  align-items: flex-start; /* Pastikan elemen dirapatkan ke kiri */
  margin-right: 20px; /* Jarak ke elemen tombol di sebelah kanan */
  gap: 10px; /* Jarak antar elemen */
}

.buttons {
  display: flex;
  flex-wrap: wrap; /* Agar tombol bisa terbungkus jika terlalu banyak */
  gap: 1px;
}

input[type="checkbox"] {
  margin-left: 40px; /* Pindahkan checkbox ke kanan */
}
